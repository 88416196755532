<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          :src="appLogoImage"
          alt="logo"
        />
        <h2 class="brand-text text-primary ml-1">
          {{ appName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Good Morning! <br>
          </b-card-title>
          <b-card-text class="mb-2">
            Be awesome with a cup of coffee in hand! ☕️
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    autocomplete="username"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Password -->
              <b-form-group
                label="Password"
                label-for="login-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      autocomplete="current-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group v-if="false">
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <hr>

              <!-- Submit button -->
              <b-button
                :disabled="loadingSubmit"
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                <b-spinner
                  v-if="loadingSubmit"
                  class="mr-50"
                  small
                />
                Sign in
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
    <b-modal
      v-model="showChallengeModal"
      title="Login Verification"
      no-close-on-esc
      no-close-on-backdrop
      :keyboard="false"
      centered
      hide-footer
    >
      <b-card-text class="mb-2">
        An OTP code already  sent to your email<br>
        Please enter the code you receive.
      </b-card-text>
      <OTPInput
        @onSubmitOTP="handleSubmitOTP"
      />
    </b-modal>

  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import OTPInput from '@/layouts/components/OTPInput.vue'

import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { $themeConfig } from '@themeConfig'
// TODO: fix all no-cycle
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import {
  superAdminAbility,
  financeAbility,
  marketerAbility,
  moderatorAbility,
  adminAbility,
  customerAbility,
} from '@/libs/acl/config'
// eslint-disable-next-line import/no-cycle
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    OTPInput,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      loadingSubmit: false,
      // Challenge
      challengeToken: undefined,
      showChallengeModal: false,
      // validation rules
      required,
      email,
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      // App Name
      appName,
      appLogoImage,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(async success => {
        if (success) {
          const body = {
            loginKey: this.userEmail,
            password: this.password,
          }

          this.loadingSubmit = true

          // authenticate user
          const { data: authData } = await useJwt.login(body)
            .finally(() => {
              this.loadingSubmit = false
            })

          const { accessToken, challengeToken } = authData.data

          if (challengeToken && !accessToken) {
            this.challengeToken = challengeToken
            this.showChallengeModal = true
          } else {
            this.onSuccess(accessToken)
          }
        }
      })
    },
    async onSuccess(accessToken) {
      // save the access token to the localstorage
      useJwt.setToken(accessToken)

      // get current active user data
      const { data: userData } = await useJwt.user()

      // get user roles
      const { roles } = userData.data

      // assign abilities or permissions based on user roles
      let ability = []
      if (roles.includes('super-admin')) {
        ability = superAdminAbility
      } else if (roles.includes('administrator')) {
        ability = adminAbility
      } else if (roles.includes('admin')) {
        ability = adminAbility
      } else if (roles.includes('finance')) {
        ability = financeAbility
      } else if (roles.includes('marketer')) {
        ability = marketerAbility
      } else if (roles.includes('moderator')) {
        ability = moderatorAbility
      } else if (roles.includes('customer')) {
        ability = customerAbility
      }

      // apply abilities
      this.$ability.update(ability)

      // put the ability data into the user data
      // because acl/ability.js authorize based on the user.ability
      const updatedUserData = { ...userData.data, ability }
      // save the user data to vuex
      await this.$store.commit('auth/UPDATE_USER_DATA', updatedUserData)

      await this.$router.replace(getHomeRouteForLoggedInUser(roles))
    },
    async handleSubmitOTP(code) {
      const payload = {
        challengeToken: this.challengeToken,
        token: code,
      }

      await useJwt.challenge(payload, { skipInterceptor: true })
        .then(response => {
          const accessToken = response?.data?.data?.accessToken || undefined
          if (accessToken) {
            this.onSuccess(accessToken)
            this.challengeToken = undefined
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
