<template>
  <div class="otp">
    <div class="otp-input__wrapper">
      <div
        v-for="(n, index) in 6"
        :id="index === 0 ? 'otp' : ''"
        :key="index"
        class="input-box"
      >
        <b-form-input
          ref="inputElements"
          v-model="otp[index]"
          type="text"
          name="code"
          class="otp-input"
          required
          @input="(val) => setInput(val, index)"
          @paste="handlePaste($event, index)"
        />
      </div>
    </div>

    <div class="otp-button__wrapper">
      <b-button
        :disabled="loadingSubmit"
        type="submit"
        variant="primary"
        block
        @click="submit"
      >
        <b-spinner
          v-if="loadingSubmit"
          class="mr-50"
          small
        />
        Submit OTP
      </b-button>
    </div>
  </div>
</template>

<script>
import { BFormInput, BButton, BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BButton,
    BSpinner,
  },
  data() {
    return {
      otp: Array(6).fill(''),
      loadingSubmit: false,
    }
  },
  mounted() {
    this.$refs.inputElements[0].focus()
  },
  methods: {
    submit() {
      const otpValue = this.otp.join('')
      this.$emit('onSubmitOTP', otpValue)
    },
    setInput(value, index) {
      this.otp.splice(index, 1, value)
      const lastInputBox = index === this.$refs.inputElements.length - 1

      if (value && !lastInputBox) {
        this.$refs.inputElements[index + 1].focus()
      }
    },
    handlePaste(event, index) {
      event.preventDefault()
      const pasteData = event.clipboardData.getData('text').slice(0, 6 - index)
      const pasteArray = pasteData.split('')

      pasteArray.forEach((char, idx) => {
        const inputIndex = index + idx
        if (inputIndex < this.otp.length) {
          this.otp.splice(inputIndex, 1, char)
        }
      })

      this.$nextTick(() => {
        if (index + pasteArray.length < this.$refs.inputElements.length) {
          this.$refs.inputElements[index + pasteArray.length].focus()
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';

.otp-input__wrapper {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-column-gap: 8px;
}

.input-box {
  width: 100%;
  padding-top: 100%;
  height: 0;
  position: relative;
  overflow: hidden;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 24px;
    text-align: center;
  }
}

.otp-button__wrapper {
  margin-top: 24px;
}
</style>
